import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "App";
import { store } from "./store/store";
import "index.scss";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
    () => {
        const version = new Date().getTime();
        const assetUrls = [
            "fonts/gofive/styles.css",
            "icons/go5-icon/style.css",
            "icons/goficon/style.css",
        ];
        assetUrls.forEach((url) => {
            const element = document.createElement("LINK");
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            element.setAttribute(
                "href",
                process.env.REACT_APP_GOFIVE_CORE_ASSET_URL +
                    url +
                    "?v=" +
                    version
            );
            document.head.appendChild(element);
        });
    }
);
