import { calTest } from "helpers/calTest";
import { TypingResult } from "interfaces/TypingResult";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRef, setCaretRef, setCaretPosition } from "store/actions";
import { State } from "store/reducer";
import { store } from "store/store";
import "stylesheets/Test.scss";

export default function Test() {
    const {
        word: { typedWord, currWord, wordList, typedHistory, caretPosition },
        time: { timer, timerId },
    } = useSelector((state: State) => state);

    const dispatch = useDispatch();
    const extraLetters = typedWord
        .slice(currWord ? currWord.length : 0)
        .split("");
    const activeWord = useRef<HTMLDivElement>(null);
    const caretRef = useRef<HTMLSpanElement>(null);

    const [testResult, setTestResult] = useState<null | TypingResult>(null);

    useEffect(() => {
        dispatch(setRef(activeWord));
        dispatch(setCaretRef(caretRef));
    }, [dispatch]);

    const checkThaiVowel = (word: string): boolean => {
        const thaiVowelsRegex =
            /[\u0E3A-\u0E3B\u0E40-\u0E49\u0E4A-\u0E4B\u0E4C\u0E31]/;
        return thaiVowelsRegex.test(word);
    };
    const isSpecificThaiVowels = (text: string): boolean => {
        const specificThaiVowelsRegex =
            /[\u0E34\u0E35\u0E36\u0E37\u0E31\u0E33]/;
        return specificThaiVowelsRegex.test(text);
    };

    const isThaiToneMark = (text: string): boolean => {
        const thaiToneMarksRegex = /[\u0E48\u0E49\u0E4A\u0E4B\u0E4C]/;
        return thaiToneMarksRegex.test(text);
    };

    useEffect(() => {
        const handlePositionCaret = () => {
            const { dispatch, getState } = store;
            const {
                word: { typedWord, activeWordRef },
            } = getState();
            const currWordEl = activeWordRef?.current!;
            let idx = typedWord.length;

            const offsetWidth =
                idx === 0
                    ? 0
                    : currWordEl?.children[idx]?.offsetLeft +
                      currWordEl?.children[idx]?.offsetWidth;
            const offsetTop = currWordEl?.children[idx]?.offsetTop;
            if (currWordEl?.children[idx] || currWordEl?.children[idx + 1]) {
                dispatch(
                    setCaretPosition({
                        left: offsetWidth,
                        top: offsetTop,
                    })
                );
            }
        };

        handlePositionCaret();
    }, [typedWord]);

    useEffect(() => {
        const testResultFn = () => {
            setTestResult(calTest());
        };

        testResultFn();
    }, [timer, typedWord, timerId]);
    return (
        <div className="test">
            <div className="wpm">{testResult?.wpm ?? 0} WPM</div>
            <div className="box">
                {wordList.map((word, idx) => {
                    const isActive =
                        currWord === word && typedHistory.length === idx;
                    return (
                        <>
                            {word === "" ? (
                                <div style={{ width: "6px" }}></div>
                            ) : (
                                <div
                                    key={word + idx}
                                    className="word"
                                    ref={isActive ? activeWord : null}>
                                    {isActive ? (
                                        <span
                                            ref={caretRef}
                                            id="caret"
                                            className="blink"
                                            style={{
                                                left: caretPosition.left,
                                                top: caretPosition.top,
                                            }}></span>
                                    ) : null}
                                    {word.split("").map((char, charId) => {
                                        return (
                                            <>
                                                {char.replace(/ /g, "") !==
                                                "" ? (
                                                    <span
                                                        key={char + charId}
                                                        style={{
                                                            marginTop:
                                                                isThaiToneMark(
                                                                    char
                                                                ) &&
                                                                (isSpecificThaiVowels(
                                                                    word.split(
                                                                        ""
                                                                    )[
                                                                        charId -
                                                                            1
                                                                    ]
                                                                ) ||
                                                                    isSpecificThaiVowels(
                                                                        word.split(
                                                                            ""
                                                                        )[
                                                                            charId +
                                                                                1
                                                                        ]
                                                                    ))
                                                                    ? "-6px"
                                                                    : "",
                                                            minWidth: "0.1px",
                                                        }}>
                                                        {checkThaiVowel(char) &&
                                                        !checkThaiVowel(
                                                            word.split("")[
                                                                charId - 1
                                                            ]
                                                        ) ? (
                                                            <span className="vowel"></span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {char}
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        );
                                    })}
                                    {isActive
                                        ? extraLetters.map((char, charId) => {
                                              return (
                                                  <span
                                                      key={char + charId}
                                                      className="wrong extra">
                                                      {char}
                                                  </span>
                                              );
                                          })
                                        : typedHistory[idx]
                                        ? typedHistory[idx]
                                              .slice(wordList[idx].length)
                                              .split("")
                                              .map((char, charId) => {
                                                  return (
                                                      <span
                                                          key={char + charId}
                                                          className="wrong extra">
                                                          {char}
                                                      </span>
                                                  );
                                              })
                                        : null}
                                </div>
                            )}
                            {word.indexOf("\n") >= 0 ? (
                                <div style={{ width: "100%" }}></div>
                            ) : (
                                <></>
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    );
}
