import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Test from "components/Test";
import { State } from "store/reducer";
import {
    setAssessmentLOGO,
    setAssessmentName,
    setTheme,
    setTime,
    setTimePassed,
    setTimerId,
    setType,
    timerSet,
} from "store/actions";
import { recordTest } from "helpers/recordTest";
import "stylesheets/themes.scss";
import { setWordList } from "store/actions";
import { TypingConfig } from "interfaces/TypingConfig";
import { calTest } from "helpers/calTest";
import { AssessmentConfig } from "interfaces/AssesmentConfig";
import Header from "components/Header";
import { startTimer } from "helpers/startTimer";

export default function App() {
    const {
        time: { timerId, timer, timerBeforeSet },
        word: { currWord, typedWord, activeWordRef, typedHistory, wordList },
    } = useSelector((state: State) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        document.onkeydown = (e) => {
            if (
                e.key.length === 1 ||
                e.key === "Backspace" ||
                e.key === "Tab" ||
                e.key === "Enter"
            ) {
                recordTest(e.key, e.ctrlKey);
                e.preventDefault();
            }
        };
        return () => {
            document.onkeydown = null;
        };
    }, [dispatch]);

    useEffect(() => {
        let idx = typedWord.length - 1;
        const currWordEl = activeWordRef?.current!;
        if (currWordEl) {
            currWordEl.children[idx + 1].classList.add(
                currWord[idx] !== typedWord[idx] ? "wrong" : "right"
            );
        }
    }, [currWord, typedWord, activeWordRef]);

    useEffect(() => {
        let idx = typedWord.length;
        const currWordEl = activeWordRef?.current!;
        if (currWordEl && idx < currWord.length)
            currWordEl.children[idx + 1].classList.remove("wrong", "right");
    }, [currWord?.length, typedWord, activeWordRef]);

    useEffect(() => {
        if (timerId) {
            dispatch(timerSet(timer));
            if (
                !timer ||
                (typedHistory.length > 0 &&
                    typedHistory.filter((word) => word.replace(/ /g, "") !== "")
                        .length ===
                        wordList.filter((word) => word.replace(/ /g, "") !== "")
                            .length)
            ) {
                clearInterval(timerId);
                dispatch(setTimerId(null));

                window.parent.postMessage(
                    { command: "typingResult", result: calTest() },
                    "*"
                );
                if (!timer) {
                    window.parent.postMessage(
                        { command: "typingTimeout" },
                        "*"
                    );
                }
            }
        }
    }, [dispatch, timer, timerId, typedHistory, wordList]);

    useEffect(() => {
        if (!timer && timerBeforeSet > 1) {
            const typingResult = calTest();
            window.parent.postMessage(
                { command: "postTypingResult", result: typingResult },
                "*"
            );
        }
    }, [dispatch, timer, timerBeforeSet]);

    useEffect(() => {
        window.parent.postMessage({ command: "getTypingWords" }, "*");

        // let a = `import { User, IUser } from './user';

        // interface UserManager {
        //     createUser(user: IUser): IUser;
        //     deleteUser(userId: number): void;
        //     updateUser(userId: number, user: IUser): IUser;
        // }

        // class SimpleUserManager implements UserManager {
        //     private users: IUser[] = [];
        //     createUser(user: IUser): IUser {
        //         this.users.push(user);
        //         console.log('User created:', user);
        //         return user;
        //     }
        //     deleteUser(userId: number): void {
        //         this.users = this.users.filter(user => user.id !== userId);
        //         console.log('User deleted with id:', userId);
        //     }
        //     updateUser(userId: number, user: IUser): IUser {
        //         let index = this.users.findIndex(u => u.id === userId);
        //         if (index !== -1) {
        //             this.users[index] = user;
        //             console.log('User updated:', user);
        //             return user;
        //         }
        //         throw new Error('User not found');
        //     }
        // }

        // let userManager = new SimpleUserManager();
        // let user1: IUser = new User(1, 'John Doe', 'john@example.com');
        // let user2: IUser = new User(2, 'Jane Doe', 'jane@example.com');
        // userManager.createUser(user1);
        // userManager.createUser(user2);
        // userManager.updateUser(1, new User(1, 'John Updated', 'johnupdated@example.com'));
        // userManager.deleteUser(2);`;

        // dispatch(setWordList(a.split(" ")));
        // dispatch(timerSet(180));
        // dispatch(setTime(180));

        window.addEventListener(
            "message",
            function (event) {
                // Check the origin of the data!
                //if (event.origin !== 'http://your-parent-origin.com') return;

                // The data sent with postMessage is stored in event.data
                if (event.data.command === "setTypingWords") {
                    const config: TypingConfig = event.data.config;
                    const theme = "default";
                    const type = "words";

                    if (typeof config.words === "string") {
                        config.words = config.words
                            .split(" ")
                            ?.filter((f) => f !== "\n");
                    }

                    dispatch(setWordList(config.words));
                    dispatch(timerSet(config.duration));
                    dispatch(setType(type));
                    dispatch(setTime(config.duration));
                    dispatch(setTheme(theme));
                } else if (event.data.command === "setAssessment") {
                    const config: AssessmentConfig = event.data.config;
                    dispatch(setAssessmentName(config.name));
                    dispatch(setAssessmentLOGO(config.logoUrl));
                } else if (event.data.command === "receiveStartTyping") {
                    dispatch(
                        setTimePassed(event?.data?.config?.timePassed ?? 0)
                    );
                    startTimer();
                }
            },
            false
        );
    }, [dispatch]);

    return (
        <>
            <Header />
            <Test />
        </>
    );
}
