import {
    appendTypedHistory,
    backtrackWord,
    setChar,
    setCaretPosition,
    setTypedWord,
} from "store/actions";
import { store } from "store/store";
import { resetTest } from "./resetTest";
import { startTimer } from "./startTimer";

const handleBackspace = (ctrlKey: boolean) => {
    const { dispatch, getState } = store;
    const {
        word: { typedWord, activeWordRef, typedHistory, wordList },
    } = getState();
    const currIdx = typedHistory.length - 1;
    const currWordEl = activeWordRef?.current!;
    if (!typedWord && typedHistory[currIdx] !== wordList[currIdx]) {
        dispatch(backtrackWord(ctrlKey));
        if (currWordEl.previousElementSibling!.innerText === "") {
            let index =
                currWordEl.parentElement!.children.length -
                1 -
                Array.from(currWordEl.parentElement!.children)
                    .reverse()
                    .findIndex((f: any) => f.className.includes("wrong"));
            currWordEl.parentElement!.children[index]!.classList.remove(
                "right",
                "wrong"
            );
        } else {
            currWordEl.previousElementSibling!.classList.remove(
                "right",
                "wrong"
            );
        }
        if (ctrlKey) {
            currWordEl.previousElementSibling!.childNodes.forEach(
                (char: HTMLSpanElement) => {
                    char.classList.remove("wrong", "right");
                }
            );
        }
    } else {
        if (ctrlKey) {
            dispatch(setTypedWord(""));
            currWordEl.childNodes.forEach((char: HTMLSpanElement) => {
                char.classList.remove("wrong", "right");
            });
        } else {
            const newTypedWord = typedWord.slice(0, typedWord.length - 1);
            dispatch(setTypedWord(newTypedWord));
        }
    }
};

export const recordTest = (key: string, ctrlKey: boolean) => {
    const { dispatch, getState } = store;
    const {
        time: { timer, timerId },
        word: { typedWord, currWord, activeWordRef, caretRef },
    } = getState();

    if (!timer) {
        if (key === "Tab") {
            resetTest();
        }
        return;
    }
    if (!timerId && key !== "Tab") startTimer();
    const currWordEl = activeWordRef?.current!;

    currWordEl?.scrollIntoView({ behavior: "smooth", block: "center" });
    const caret = caretRef?.current!;
    if (caret) {
        caret.classList.remove("blink");
        setTimeout(() => caret.classList.add("blink"), 500);
    }
    switch (key) {
        case "Tab":
            break;
        case " ":
        case "Enter":
            if (typedWord === "" || !currWordEl) return;

            dispatch(
                setCaretPosition({
                    left: 0,
                    top: 0,
                })
            );
            currWordEl.classList.add(
                typedWord !== currWord.replaceAll("\n", "") ? "wrong" : "right"
            );
            dispatch(appendTypedHistory());
            break;
        case "Backspace":
            handleBackspace(ctrlKey);
            break;
        default:
            dispatch(setChar(typedWord + key));
            break;
    }
};
