import { RefObject } from "react";
import { AnyAction, combineReducers } from "redux";
import {
    SET_CHAR,
    SET_WORD,
    TIMER_DECREMENT,
    TIMERID_SET,
    TIMER_SET,
    APPEND_TYPED_HISTORY,
    PREV_WORD,
    SET_WORDLIST,
    SET_THEME,
    SET_TIME,
    SET_REF,
    SET_CARET_REF,
    SET_TYPE,
    SET_CARET_POSITION,
    SET_ASSESSMENT_NAME,
    SET_ASSESSMENT_LOGO,
    SET_TIME_PASSED,
} from "./actions";
import { CaretPosition } from "interfaces/AssesmentConfig";

export interface State {
    assessment: {
        name: string;
        logoUrl: string;
    };
    preferences: {
        theme: string;
        timeLimit: number;
        type: string;
    };
    word: {
        currWord: string;
        typedWord: string;
        typedHistory: string[];
        wordList: string[];
        activeWordRef: RefObject<HTMLDivElement> | null;
        caretRef: RefObject<HTMLSpanElement> | null;
        caretPosition: CaretPosition;
    };
    time: {
        timer: number;
        timerId: NodeJS.Timeout | null;
        timerBeforeSet: number;
        timerPassed: number;
    };
}

export const initialState: State = {
    assessment: {
        name: "",
        logoUrl: "",
    },
    preferences: {
        theme: "",
        timeLimit: 0,
        type: "",
    },
    word: {
        currWord: "",
        typedWord: "",
        typedHistory: [],
        wordList: [""],
        activeWordRef: null,
        caretRef: null,
        caretPosition: {
            left: 0,
            top: 0,
        },
    },
    time: {
        timer: 0,
        timerId: null,
        timerBeforeSet: 0,
        timerPassed: 0,
    },
};

const timerReducer = (
    state = initialState.time,
    { type, payload }: AnyAction
) => {
    switch (type) {
        case TIMER_DECREMENT:
            return { ...state, timer: state.timer - 1 };
        case TIMER_SET:
            return {
                ...state,
                timerBeforeSet: state.timerId ? state.timer : payload,
                timer: payload,
            };
        case TIMERID_SET:
            return { ...state, timerId: payload };
        case SET_TIME_PASSED:
            return { ...state, timerPassed: payload };
        default:
            return state;
    }
};

const wordReducer = (
    state = initialState.word,
    { type, payload }: AnyAction
) => {
    switch (type) {
        case SET_CHAR:
            return { ...state, typedWord: payload };
        case SET_WORD:
            return { ...state, typedHistory: [...state.typedHistory, payload] };
        case APPEND_TYPED_HISTORY:
            const nextIdx = state.wordList.findIndex(
                (_, index) =>
                    index >= state.typedHistory.length + 1 &&
                    state.wordList[index] !== ""
            );
            let tmpTypedHistory = [...state.typedHistory, state.typedWord];

            if (nextIdx > state.typedHistory.length) {
                const tmpSpace = Array(
                    nextIdx - state.typedHistory.length
                ).join(" ");
                tmpTypedHistory = [...tmpTypedHistory, ...tmpSpace.split("")];
            }
            return {
                ...state,
                typedWord: "",
                currWord: state.wordList[nextIdx],
                typedHistory: [...tmpTypedHistory],
            };
        case PREV_WORD:
            const prevIdx =
                state.typedHistory.length -
                1 -
                state.typedHistory.findIndex((_, index) => {
                    const _index = state.typedHistory.length - (index + 1);
                    return (
                        state.typedHistory[_index]?.replaceAll(" ", "") !== ""
                    );
                });
            return {
                ...state,
                currWord: state.wordList[prevIdx],
                typedWord: !payload ? state.typedHistory[prevIdx] : "",
                typedHistory: state.typedHistory.splice(0, prevIdx),
            };
        case SET_REF:
            return {
                ...state,
                activeWordRef: payload,
            };
        case SET_CARET_REF:
            return {
                ...state,
                caretRef: payload,
            };
        case SET_WORDLIST:
            const areNotWords = payload.some((word: string) =>
                word.includes(" ")
            );
            const wordList: string[] = areNotWords
                ? payload.flatMap((token: string) => token.split(" "))
                : payload;
            return {
                ...state,
                typedWord: "",
                typedHistory: [],
                currWord: wordList[0],
                wordList,
            };
        case SET_CARET_POSITION:
            return {
                ...state,
                caretPosition: {
                    ...payload,
                },
            };
        default:
            return state;
    }
};

const preferenceReducer = (
    state = initialState.preferences,
    { type, payload }: AnyAction
) => {
    switch (type) {
        case SET_THEME:
            return { ...state, theme: payload };
        case SET_TIME:
            return {
                ...state,
                timeLimit: payload,
            };
        case SET_TYPE:
            return {
                ...state,
                type: payload,
            };
        default:
            return state;
    }
};

const assessmentReducer = (
    state = initialState.assessment,
    { type, payload }: AnyAction
) => {
    switch (type) {
        case SET_ASSESSMENT_NAME:
            return { ...state, name: payload };
        case SET_ASSESSMENT_LOGO:
            return {
                ...state,
                logoUrl: payload,
            };
        default:
            return state;
    }
};

export default combineReducers({
    time: timerReducer,
    word: wordReducer,
    preferences: preferenceReducer,
    assessment: assessmentReducer,
});
