import { CalculatedWordModel, TypingResult } from "interfaces/TypingResult";
import { store } from "store/store";

export const calTest = (): TypingResult => {
    const { getState } = store;
    const {
        word: { wordList, typedHistory, typedWord },
        preferences: { timeLimit },
        time: { timerBeforeSet, timerPassed },
    } = getState();

    let words = wordList.filter((word) => word.replace(/ /g, "") !== "");
    let histories = typedHistory
        .concat([typedWord])
        .filter((word) => word.replace(/ /g, "") !== "");
    const spaces = histories.length || 0;

    const wordsModel = histories.map((typedWord, idx) =>
        countMatchingChars(words[idx], typedWord)
    );

    let correctChars = wordsModel?.reduce((sum, current) => {
        return sum + current.correct;
    }, 0);

    let incorrect = wordsModel?.reduce((sum, current) => {
        return sum + current.incorrect;
    }, 0);

    const elapsedTime = timeLimit - timerBeforeSet + timerPassed;
    const actualElapsedTime = elapsedTime < timeLimit ? elapsedTime : timeLimit;

    const typedWords = correctChars + incorrect;
    const accuracyUnround = correctChars / typedWords;
    const accuracyUnroundTotal = accuracyUnround * 100;
    const accuracy = Math.round(accuracyUnroundTotal);
    const typingResult: TypingResult = {
        wpm: Math.round(
            (correctChars + spaces) / 5 / ((actualElapsedTime || 1) / 60)
        ),
        correct: correctChars,
        incorrect,
        totalWords: wordList.length,
        accuracy,
        typedWord: typedWords,
        elapsedTime: actualElapsedTime,
        typedHistory: histories,
    };
    return typingResult;
};

function countMatchingChars(
    correctWord: string,
    typedWord: string
): CalculatedWordModel {
    let matchingCount = 0;
    let typedWordIndex = typedWord.length - 1;

    while (typedWordIndex >= 0) {
        if (
            typedWord &&
            correctWord &&
            typedWord[typedWordIndex] === correctWord[typedWordIndex]
        ) {
            matchingCount++;
        }

        typedWordIndex--;
    }

    return {
        incorrect: Math.max(
            typedWord?.length - matchingCount,
            correctWord?.length - matchingCount
        ),
        correct: matchingCount,
    };
}
