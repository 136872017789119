import { State } from "store/reducer";
import { useSelector } from "react-redux";
import "stylesheets/Header.scss";
import { useEffect, useState } from "react";
export interface Options {
    time: number[];
    theme: string[];
    type: string[];
}

export const options: Options = {
    time: [15, 30, 45, 60, 120],
    theme: [
        "default",
        "mkbhd",
        "mocha",
        "coral",
        "ocean",
        "azure",
        "forest",
        "rose-milk",
        "amethyst",
        "amber",
        "terminal",
        "vscode",
        "mountain",
        "pink-sky",
        "red-season",
    ],
    type: ["words", "sentences", "numbers", "got", "javascript", "python"],
};

export default function Header() {
    const {
        time: { timer, timerBeforeSet, timerPassed },
        assessment: { name, logoUrl },
        preferences: { timeLimit },
    } = useSelector((state: State) => state);
    const [timeRunner, setTimeRunner] = useState<string>("");

    useEffect(() => {
        const changeTimeFormatFn = () => {
            const hours = Math.floor(timer / 3600);
            const minutes = Math.floor((timer % 3600) / 60);
            const seconds = timer % 60;

            const formattedTime = `${String(hours).padStart(2, "0")}:${String(
                minutes
            ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
            setTimeRunner(formattedTime);
        };

        changeTimeFormatFn();
    }, [timer]);
    return name || logoUrl ? (
        <>
            <div className="test-header-container">
                <div className="test-header-wrapper">
                    <span className="test-header-detail">
                        {logoUrl ? <img src={logoUrl} alt="" /> : <></>}
                        <span>{name}</span>
                    </span>
                    <span className="test-header-time-interval">
                        <em className="gf-icon-timer"></em>
                        <span>{timeRunner}</span>
                    </span>
                </div>
            </div>
            <div className="progress-bar-container">
                <div
                    className="progress-bar"
                    style={{
                        width: `${
                            ((timeLimit - timerBeforeSet + timerPassed) /
                                (timeLimit + timerPassed)) *
                                100 +
                            ((timeLimit - timerBeforeSet + timerPassed) /
                                (timeLimit + timerPassed)) *
                                (1 / (timeLimit + timerPassed)) *
                                100
                        }%`,
                    }}></div>
            </div>
        </>
    ) : (
        <></>
    );
}
